<template>
	<main class="home-view full-container">
		<div class="hero-content full-container">
			<div class="hero-image full-container" />

			<div class="message">
				<div class="first-section">
					<span class="welcome">Welcome to BetterPhoto!</span>
				
					<span class="join">Join The Fun</span>
				</div>

				<div class="second-section">
					<span class="invitation">
						Is photography your passion? Make your fun hobby even better. 
						Take a course. Get a website. Started in 1996, we are one of the 
						longest lasting communities of creative photography enthusiasts. 
						Join us!
					</span>

					<div class="buttons">
						<a class="sign-up">
							Sign Up
						</a>

						<a class="log-in">
							Log In
						</a>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'HomeView',

	setup() {
		// TODO	
	}
})
</script>

<style lang="scss" scoped>
.home-view {
	padding: 16px 16px 0 16px;
	//border: 1px solid #f00;
	height: 100%;

	@media only screen and (max-width: 480px) {
		padding: 0;
	}

	.hero-content {
		justify-content: center;
		align-items: flex-end;
		border-radius: 8px;
		overflow: hidden;
		border: 1px solid #090b12;
		position: relative;
		//filter: saturate(0);
		//border: 1px solid #ff0;

		@media only screen and (max-width: 480px) {
			border-radius: 0;
			border: none;
		}

		.hero-image {
			position: absolute;
			top: 0;
			left: 0;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url(/images/friends_001.jpg);
		}

		.message {
			display: flex;
			justify-content: center;
			font-family: Comfortaa;
			color: #eee;
			//backdrop-filter: blur(5px);
			overflow: hidden;
			width: 100%;
			height: auto;
			background-color: rgba(#090B12, 0.9);
			z-index: 1;

			@media only screen and (max-width: 720px) {
				flex-direction: column;
			}

			@media only screen and (max-height: 680px) and (max-width: 375px) {
				height: 100%;
			}

			.first-section {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				min-width: 495px;
				padding: 18px 12px 18px 24px;
				//border: 1px solid #f00;

				@media only screen and (max-width: 1375px) {
					min-width: 300px;
				}

				@media only screen and (max-width: 720px) {
					align-items: flex-start;
					padding: 18px 12px 0 12px;
				}
				
				@media only screen and (max-width: 515px) {
					align-items: center;
				}

				.welcome {
					display: inline-block;
					font-weight: 500;
					font-size: 36px;
					line-height: 44px;
					text-align: right;

					@media only screen and (max-width: 720px) {
						text-align: left;
					}

					@media only screen and (max-width: 580px) {
						font-size: 32px;
						line-height: 40px;
					}

					@media only screen and (max-width: 515px) {
						text-align: center;
					}
				}

				.join {
					display: inline-block;
					font-size: 28px;
					line-height: 36px;
					text-transform: uppercase;
					color: #E6A01F;
					text-align: right;
					
					@media only screen and (max-width: 720px) {
						text-align: left;
					}

					@media only screen and (max-width: 580px) {
						font-size: 26px;
						line-height: 34px;
					}
					
					@media only screen and (max-width: 515px) {
						text-align: center;
					}
				}
			}

			.second-section {
				display: flex;
				flex-direction: column;
				padding: 18px 24px 18px 12px;

				@media only screen and (max-width: 720px) {
					padding: 18px 12px;
				}
				
				@media only screen and (max-width: 515px) {
					align-items: center;
					text-align: center;
				}
				
				.invitation {
					font-size: 16px;
					line-height: 26px;
				}

				.buttons {
					display: grid;
					grid-template-columns: 100px 100px;
					gap: 8px;
					margin-top: 8px;

					.sign-up {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #E6A01F;
						padding: 4px 16px;
						border-radius: 4px;
						font-weight: bolder;
						text-transform: lowercase;
					}

					.log-in {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #F16719;
						padding: 4px 16px;
						border-radius: 4px;
						font-weight: bolder;
						text-transform: lowercase;
					}
				}
			}
		}
	}
}
</style>